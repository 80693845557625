
.bg-img {
  position: absolute;
  background-color: black;
}
.login-top {
  width: 100vw;
  height: 70px;
  background-color: black;
  padding: 10px 20px;
}
.login-top img {
  width: 200px;
}

.login-box {
  position: relative;
  background-color: rgb(0, 0, 0);
  width: 400px;
  height: 300px;
  margin-bottom: 11em;
  margin-right: 1em;
  box-sizing: border-box;
}

.small-text {
  font-size: 13px;
}

.form-control {
  box-shadow: none;
}

.new-user p {
  text-decoration: none;
}
