.midcard {
  position: relative;
  width: 100%;
}

.image-container {
  position: relative;
}

.card-img {
  width: 100%;
  height: 300px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.midcard:hover .overlay {
  opacity: 1;
}
