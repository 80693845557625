body {
  font-family: fantasy;
  margin: 0;
  padding: 0;
  width: 100%;
  scroll-behavior: smooth;
}

.cascading-right {
  margin-right: -5 0px;
}

@media (max-width: 650px) {
  .cascading-right {
    margin-right: 0;
  }
}

.logo {
  margin-left: 100px;
}

.bg-img {
  background-image: url(../../assets/images/signup_img/background_img.jpg);
  max-width: 100%;
  height: auto;
  position: absolute;
  box-sizing: border-box;
}

.card-custom {
  margin-top: 400px;
  background-color: rgba(196, 37, 37, 0.821);
  border-radius: 50px;
  width: 200%;
  margin-right: 50px;
  max-width: 400px;
  min-height: 300px;
}

.card-body h2 {
  color: black;
}

.form-label {
  color: black;
  font-weight: 200px;
  font-size: medium;
  margin-top: 10px;
}

.logo-container {
  margin-left: 800px;
  margin-bottom: 20px;
  padding: 20px;
  position: absolute;

  align-items: center;
}

.logo-container img {
  float: left;
  max-width: 100%;
  height: auto;
}
.submit_btn {
  text-decoration: none;
  color: white;
}

.form-label {
  font-family: fantasy;
  font-size: large;
}
