.card {
  position: relative;
  width: 14rem; 
  margin: auto; 
}

.slider-card {
  width: 100%;
  height: auto;
}

.card-body {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 0.5rem;
  text-align: center;
}

.play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  border: none;
  cursor: pointer;
}
.play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: none;
  border: none;
  color: white;
  opacity: 0.2;
  transition: opacity 0.3s ease;
}

.play-btn:hover {
  opacity: 1;
}