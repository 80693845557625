@import url("https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap");

.navbar-brand {
  margin-top: 10px;
}

.home{
  color: red;
}

.navbar a {
  display: block;
  color: red;

  text-decoration: none;
  font-size: 25px;
}

.navbar a:hover {
  color: red;
}

nav ul li {
  margin-left: 20px;
  padding-top: 2px;
  list-style-type: none;
  font-family: fantasy;
}

nav ul a {
  font-size: 60px;
  text-decoration: none;
  color: red;
  font-family: fantasy;
}

.nav-dropdown {
  font-size: 25px;
}

.nav-dropdown:hover {
  color: white;
}

/* Sreachbar style*/

.search-container {
  background: transparent;
  height: 30px;
  margin-right: 10px;
  border-radius: 30px;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.8s;
  
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.3),
    -4px -4px 6px 0 rgba(116, 125, 136, 0.2),
    inset -4px -4px 6px 0 rgba(255, 255, 255, 0.2),
    inset 4px 4px 6px 0 rgba(0, 0, 0, 0.2);
}

.search-container:hover > .search-input {
  width: 200px;
 
}

.search-container .search-input {
  background: transparent;
  border: none;
  outline: none;
  width: 0px;
  font-weight: 500;
  font-size: 16px;
  transition: 0.8s;
}

.search-container .search-btn .fas {
  color: red;
  
}

@keyframes hoverShake {
  0% {
    transform: skew(0deg, 0deg);
  }
  25% {
    transform: skew(5deg, 5deg);
  }
  75% {
    transform: skew(-5deg, -5deg);
  }
  100% {
    transform: skew(0deg, 0deg);
  }
}

.search-container:hover {
  animation: hoverShake 0.15s linear 3;
}



.account-icon {
  margin-top: 10px;
  margin-right: 10px;
  padding-left: 20px;
}

.home {
  color: red;
  font-family: fantasy;
}
