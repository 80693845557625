.bg-color {
  background-color: black;
}

h2 {
  font-family: fantasy;
  text-align: center;
  color: red;
}

h3 {
  font-family: fantasy;
  text-align: center;
  color: red;
}

